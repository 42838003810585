import React from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {RuntimeContext} from '../../../../../commons/components/runtime-context/runtime-context'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {MemberAppProps} from '../../../interface'
import {shouldLoadMore} from '../../selectors/events'
import {AppContainer} from '../app-container'
import {usePrevious} from '../../../../../commons/hooks/use-previous'

const SCROLL_CUTOFF = 0.95

export const App = (props: MemberAppProps) => {
  const {state, isRTL, host, actions} = props
  const {isMobile, isPreview} = useEnvironment()
  const prevFormFactor = usePrevious(host.formFactor)

  React.useEffect(() => {
    host.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(state)) {
        actions.getEvents(state.tab)
      }
    })
  }, [])

  React.useEffect(() => {
    if (actions && host && state) {
      if (isPreview && host.formFactor !== prevFormFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }, [host.formFactor])

  if (!state) {
    return <ErrorPage viewMode={host.viewMode} />
  }

  return (
    <RuntimeContext.Provider value={props}>
      <TPAComponentsProvider value={{mobile: isMobile}}>
        <DatesProvider dates={state.dates}>
          <AppContainer isRTL={isRTL} />
        </DatesProvider>
      </TPAComponentsProvider>
    </RuntimeContext.Provider>
  )
}
